import styled from "styled-components";
import { palette } from "styled-theme";
import { transition, borderRadius } from "../../settings/style-util";
import WithDirection from "../../settings/withDirection";

const SliderBoxWrapper = styled.div`
  display: flex;
  height: 100%;
  min-height: 300px;
  background: none;

  @media only screen and (max-width: 767px) {
    height: auto;
    flex-direction: column;

    &.ant-layout.ant-layout-has-sider {
      flex-direction: column;
    }
  }

  .isoFilterBoxWrapper {
    background: #ffffff;

    .isoFooter {
      height: auto;
      line-height: inherit;
      padding: 10px 10px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      flex-direction: row;
      background-color: #ffffff;
      border-bottom: 1px solid ${palette("border", 0)};
      justify-content: center;
    }

    .form-kra {
      .ant-form-item-label{
        text-align: left;
      }
      .ant-form-item-label label{
        font-weight: bold;
      }
      .error{
        color:#FF0000;
      }
    }

    .isoHeader {
      height: auto;
      line-height: inherit;
      padding: 10px 10px;
      display: flex;
      color: #FFFFFF;
      font-weight: bold;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      flex-direction: row;
      background-color: #0c5387;
      border-bottom: 1px solid ${palette("border", 0)};

      .isoIconWrapper {
        cursor: pointer;
        margin: 0px 5px;
      }      

      @media only screen and (max-width: 480px) {
        padding: 20px;
      }

      @media only screen and (max-width: 400px) {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
      }
    }

    .isoFilterContent {
      height: 100%;
      padding: 20px;
      ul.view_kpi{
        list-style: none;
        font-size: 14px;
        padding-left: 15px;
      }
      ul.view_kpi li{
        margin: 5px 2px;
      }
      ul.view_kpi li p{
        margin: 5px 0px;
      }
      ul.view_kpi li span{
        font-weight: bold;
        font-size: 15px;
        margin-right: 3px;
      }
      .ant-form-item{
        margin-bottom: 0;
      }
      @media (max-width: 800px) {
        height: 300px;
      }
    }

    @media (max-width: 767px) {
      .isoNoteListSidebar.ant-layout-sider {
        width: auto !important;
        margin-bottom: 30px;
        flex: 0 0 450px !important;
      }
    }
  }
`;

export default WithDirection(SliderBoxWrapper);
