/**
 * User selectors
 */

import { createSelector } from "reselect";

const selectUsers = (state) => state.Users;

const getAllUsers = () =>
  createSelector(selectUsers, (userState) => userState.get("users"));

const getLoading = () =>
  createSelector(selectUsers, (userState) => userState.get("loading"));

const getStatus = () =>
  createSelector(selectUsers, (userState) => userState.get("status"));

const getUserDetails = () =>
  createSelector(selectUsers, (userState) => userState.get("user"));

const getSearchKey = () =>
  createSelector(selectUsers, (userState) => userState.get("key"));

const getUsers = () =>
  createSelector(
    getAllUsers(),
    getSearchKey(),
    (allUsers, key) =>
      allUsers &&
      allUsers.filter((user) => {
        key = key ? key.toLowerCase() : "";
        let val = "";
        if (key) {
          val =
            user &&
            (user.firstName.toLowerCase().indexOf(key) > -1 ||
              (user.lastName && user.lastName.toLowerCase().indexOf(key) > -1));
          // (user.school &&
          //   user.school.name.toLowerCase().indexOf(key) > -1) ||
        } else {
          val = true;
        }
        return val;
      })
  );

const getConfigs = () =>
  createSelector(selectUsers, (userState) => {
    return {
      schools: userState.get("schools"),
      roles: userState.get("roles"),
      subjects: userState.get("subjects"),
      grades: userState.get("grades"),
    };
  });

export {
  getUsers,
  getUserDetails,
  getConfigs,
  getLoading,
  getStatus,
  getAllUsers,
};
