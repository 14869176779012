import React, { useEffect } from "react";
import {
  Select,
  Button,
  Form,
  Card,
  Icon,
  Input,
  Col,
  Modal,
  Tooltip,
} from "antd";
import API from "../../helpers/API";
import { pageSize, getTableValues } from "../utility/fetchutility";
import Spins from "../Spin/spin.style";
import "./student.css";
import Filter from "../../components/filter/studentFilter";
import SortTable from "../../components/tables/antTables/tableViews/CustomExapandedTable";

const { Meta } = Card;
export default function Students({
  onAdd,
  selectedVal,
  isMultiple,
  preFilter,
  fields,
  addedStudents,
  addAlreadyselected,
  onFieldChange,
  schoolList = false,
  classroomList = false,
  orgList = false,
  disabled = false,
  orgDisabled= false
}) {
  const api = API.users;
  const [selectedOptions, setSelectedOptions] = React.useState([]);
  const [selectedOptionsList, setSelectedOptionsList] = React.useState([]);
  const [list, setList] = React.useState([]);
  const [filteredList, setFilteredList] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [studentModal, setStudentModal] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [totalpage, setTotalPage] = React.useState(0);
  const [loadMore, setLoadMore] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [openFilters, setOpenFilters] = React.useState(false);
  const [filter, setFilter] = React.useState({});
  const [addedStudentsModal, setAddedStudentsModal] = React.useState(false);
  const handleSelectChange = (selectedValue) => {
    const selectedItems = list.filter((option) =>
      [selectedValue].includes(option.id)
    );
    if (isMultiple) {
      if (selectedOptions.includes(selectedValue)) {
        setSelectedOptionsList(
          selectedOptionsList.filter((item) => item.id !== selectedValue)
        );
        setSelectedOptions(
          selectedOptions.filter((item) => item !== selectedValue)
        );
      } else {
        setSelectedOptionsList([...selectedOptionsList, selectedItems[0]]);
        setSelectedOptions([...selectedOptions, selectedValue]);
      }
    } else {
      setSelectedOptionsList([selectedItems[0]]);
      setSelectedOptions([selectedValue]);
    }
  };
  const setStateValue = (values = {}) => {
    if (values?.list) {
      setList([...list, ...values?.list]);
      setFilteredList([...filteredList, ...values?.list]);
      setTotalPage(values?.totalPages);
      setTimeout(
        () => {
          setLoadMore(false);
          setLoading(false);
        },
        [1000]
      );
    }
  };

  const handleSelectAll = (values = {}) => {
    setSelectedOptions(values?.list?.map((item) => item.id) || []);
    setSelectedOptionsList(values?.list || []);
    setLoading(values?.loader);
  };

  const handleSearchChange = (values = {}) => {
    setList(values?.list || []);
    setFilteredList(values?.list || []);
    setTotalPage(values?.totalPages);
    setLoadMore(values?.loader);
    setLoading(values?.loader);
  };
  const getSelectAll = () => {
    getTableValues(
      {
        ...filter,
        search: search,
      },
      handleSelectAll,
      api.classroomList,
      "students"
    );
  };
  const getTableList = (param = { search: search }, remove = false) => {
    getTableValues(
      { ...param, limit: 16 },
      param.search || remove ? handleSearchChange : setStateValue,
      api.classroomList,
      "students"
    );
  };
  const onSubmit = () => {
    onAdd(isMultiple ? selectedOptions : selectedOptions[0]);
    addAlreadyselected(selectedOptionsList);
    onModalCancel(false);
  };
  useEffect(
    () => {
      setSelectedOptions(selectedVal ?? []);
      setSelectedOptionsList(addedStudents || []);
    },
    [selectedVal, addedStudents]
  );
  // useEffect(
  //   () => {
  //     if (filter?.school) {
  //       const schoolField = (fields || []).find((f) => f === "school");
  //       if (schoolField) {
  //         const schoolChangeHandler = onFieldChange?.find(
  //           (f) => f.field === "school"
  //         )?.handleChange;
  //         if (schoolChangeHandler) {
  //           schoolChangeHandler(filter.school);
  //         }
  //       }
  //     }
  //     if (filter?.schools) {
  //       const schoolField = (fields || []).find((f) => f === "schools");
  //       if (schoolField) {
  //         const schoolChangeHandler = onFieldChange?.find(
  //           (f) => f.field === "schools"
  //         )?.handleChange;
  //         if (schoolChangeHandler) {
  //           schoolChangeHandler(filter.schools);
  //         }
  //       }
  //     }
  //     if (filter?.classroom) {
  //       const classField = (fields || []).find((f) => f === "classroom");
  //       console.log(classField)
  //       if (classField) {
  //         const classChangeHandler = onFieldChange?.find(
  //           (f) => f.field === "classroom"
  //         )?.handleChange;
  //         console.log(classChangeHandler)
  //         if (classChangeHandler) {
  //           classChangeHandler(filter.classroom);
  //         }
  //       }
  //     }
  //   },
  //   [filter]
  // );
  useEffect(
    () => {
      setFilter(preFilter);
      setLoading(true);
      setSelectedOptions(selectedVal ?? []);
      getTableList({ ...preFilter, page: page }, true);
    },
    [JSON.stringify(preFilter)]
  );
  const handleSearch = (e) => {
    setPage(1);
    setLoading(true);
    setSearch(e.target.value);
    getTableList(
      {
        search: e.target.value,
        ...filter,
        page: 1,
      },
      true
    );
  };
  const loadmoreHandler = () => {
    setPage(page + 1);
    setLoadMore(true);
    setLoading(true);
    getTableList({
      search: search,
      page: page + 1,
      ...filter,
    });
  };
  const filterSubmit = (filters) => {
    setFilter(filters);
    setSearch("");
    setSelectedOptions([]);
    setSelectedOptionsList([]);
    onAdd([])
    if (filters?.school) {
      const schoolField = (fields || []).find((f) => f === "school");
      if (schoolField) {
        const schoolChangeHandler = onFieldChange?.find(
          (f) => f.field === "school"
        )?.handleChange;
        if (schoolChangeHandler) {
          schoolChangeHandler(filters.school);
        }
      }
    } else if (filters?.schools) {
      const schoolField = (fields || []).find((f) => f === "schools");
      if (schoolField) {
        const schoolChangeHandler = onFieldChange?.find(
          (f) => f.field === "schools"
        )?.handleChange;
        if (schoolChangeHandler) {
          schoolChangeHandler(filters.schools);
        }
      }
    } else if ("classroom" in filters) {
      const classField = (fields || []).find((f) => f === "classroom");
      if (classField) {
        const classChangeHandler = onFieldChange?.find(
          (f) => f.field === "classroom"
        )?.handleChange;
        if (classChangeHandler) {
          classChangeHandler(filters.classroom);
        }
      }
    } else if (filters?.classrooms) {
      const classField = (fields || []).find((f) => f === "classrooms");
      if (classField) {
        const classChangeHandler = onFieldChange?.find(
          (f) => f.field === "classrooms"
        )?.handleChange;
        if (classChangeHandler) {
          classChangeHandler(filters.classrooms);
        }
      }
    } else if (filters?.organizations) {
      const orgField = (fields || []).find((f) => f === "organizations");
      if (orgField) {
        const orgChangeHandler = onFieldChange?.find(
          (f) => f.field === "organizations"
        )?.handleChange;
        if (orgChangeHandler) {
          orgChangeHandler(filters.organizations);
        }
      }
    } else if (filters?.organization) {
      const orgField = (fields || []).find((f) => f === "organization");
      if (orgField) {
        const orgChangeHandler = onFieldChange?.find(
          (f) => f.field === "organization"
        )?.handleChange;
        if (orgChangeHandler) {
          orgChangeHandler(filters.organization);
        }
      }
    }
  };
  const onSelectAll = () => {
    setLoading(true);
    getSelectAll();
  };
  const onClear = () => {
    onAdd([]);
    addAlreadyselected([]);
    setSelectedOptions([]);
    setSelectedOptionsList([]);
  };
  const onModalCancel = (notfromSubmit = true) => {
    setSelectedOptions(selectedVal ?? []);
    notfromSubmit && setSelectedOptionsList(addedStudents || []);
    setStudentModal(false);
    setOpenFilters(false);
    setPage(1);
    setSearch("");
  };

  const openAddedStudentsModal = () => {
    setAddedStudentsModal(true);
  };

  const closeAddedStudentsModal = () => {
    setAddedStudentsModal(false);
  };
  return (
    <>
      <Tooltip placement="top" title={"Add Students"}>
        <Button
          disabled={disabled}
          style={{ width: "100%", height: "30px" }}
          onClick={() => setStudentModal(true)}
          // title={"Add Students"}
        >
          {selectedOptionsList?.length
            ? `Added (${selectedOptionsList?.length})`
            : isMultiple
              ? "Add Students"
              : "Add Student"}
        </Button>
      </Tooltip>
      {studentModal && (
        <Filter
          from="studentSelect"
          isSliderOpen={openFilters}
          close={() => setOpenFilters(false)}
          fields={fields || []}
          items={"studentFilter"}
          submit={filterSubmit}
          preFilter={preFilter}
          schoolList={schoolList}
          classroomList={classroomList}
          orgList={orgList}
        />
      )}
      <Modal
        getContainer={false}
        backdrop={false}
        title={isMultiple ? "Select Students" : "Select Student"}
        visible={studentModal}
        footer={null}
        maskClosable={false}
        destroyOnClose
        closable
        onCancel={onModalCancel}
        width={1200}
        height={200}
      >
        <Modal
          title="Added Students"
          visible={addedStudentsModal}
          onCancel={closeAddedStudentsModal}
          footer={null}
          width={500}
        >
          <div className="added-students-list">
            {selectedOptionsList.length > 0 ? (
              selectedOptionsList.map((student) => (
                <Card key={student.id} className="added-student-card">
                  <Meta
                    title={`${student.firstName} ${student.lastName}`}
                    description={student.school?.name}
                  />
                </Card>
              ))
            ) : (
              <div>No students added yet</div>
            )}
          </div>
        </Modal>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Spins spinning={loading}>
            <div className="web_select_modal_head">
              <div>
              <Input
                id="student-search"
                type="text"
                placeholder="Search"
                style={{ width: "300px" , marginTop: "15px"}}
                className="search-input"
                suffix={<Icon type="search" />}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch(e);
                  }
                }}
              />
              </div>
              {/* {(studentModal && fields && !loading) && (
                <Filter
                  from="studentSelect"
                  isSliderOpen={openFilters}
                  close={() => setOpenFilters(false)}
                  fields={fields || []}
                  items={"studentFilter"}
                  submit={filterSubmit}
                  preFilter={preFilter}
                  schoolList={schoolList}
                  classroomList={classroomList}
                  orgList={orgList}
                />
              )} */}
              {/* <Button
              onClick={openAddedStudentsModal}
              style={{ width: "250px", height: "30px", marginTop: "10px" }}
            >
              View Added Students
            </Button> */}
              <div className="student_headder_action">
              {(studentModal && fields && !loading) && (
                <Filter
                  from="studentSelect"
                  isSliderOpen={openFilters}
                  close={() => setOpenFilters(false)}
                  fields={fields || []}
                  items={"studentFilter"}
                  submit={filterSubmit}
                  preFilter={preFilter}
                  schoolList={schoolList}
                  classroomList={classroomList}
                  orgList={orgList}
                  orgDisabled={orgDisabled}
                />
              )}
              <div>
                {isMultiple && (
                  <>
                    <Button
                      style={{ height: "30px", marginRight: "20px" }}
                      title="Cancel"
                      onClick={onClear}
                    >
                      Remove All
                    </Button>
                    <Button
                      style={{ height: "30px" }}
                      title="Cancel"
                      type="primary"
                      onClick={onSelectAll}
                    >
                      Select All
                    </Button>
                  </>
                )}
                </div>
                  {/* {fields && (
                    <Icon
                      type="unordered-list"
                      onClick={(e) => setOpenFilters(true)}
                      title="Filter Apply"
                      theme="outlined"
                      className="edit-icon"
                    />
                  )} */}
              </div>
            </div>
            <div className="web_listing_card_wrapper">
              {filteredList?.map((item) => {
                return (
                  // <Card
                  //   onClick={() => handleSelectChange(item?.id)}
                  //   className="website_po_card"
                  //   cover={
                  //     <img
                  //       style={{ objectFit: "cover", height: "100%" }}
                  //       alt="example"
                  //       src={
                  //         item?.avatar?.urlLowQuality ||
                  //         JSON.parse(
                  //           localStorage.getItem("DefaultLogos")
                  //         )?.find((item) => item.title == "student")?.logo
                  //       }
                  //     />
                  //   }
                  // >
                  //   {selectedOptions.includes(item?.id) && (
                  //     <Icon
                  //       className="website_po_card_icon"
                  //       type="check-circle"
                  //       theme="twoTone"
                  //       twoToneColor="#52c41a"
                  //     />
                  //   )}
                  //   <Meta
                  //     title={`${item?.firstName} ${item?.lastName}`}
                  //     description={item?.school?.name}
                  //   />
                  // </Card>
                  <Card
                    onClick={() => handleSelectChange(item?.id)}
                    className="website_po_cards"
                  >
                    <div className="card-content-wrapper">
                      <div className="card-image">
                        <img
                          style={{
                            objectFit: "cover",
                            height: "100%",
                            width: "100%",
                            borderRadius: "100px",
                            padding: "10px",
                          }}
                          alt="example"
                          src={
                            item?.avatar?.urlLowQuality ||
                            JSON.parse(
                              localStorage.getItem("DefaultLogos")
                            )?.find((logo) => logo.title === "student")?.logo
                          }
                        />
                      </div>
                      <div className="card-details">
                        {selectedOptions.includes(item?.id) && (
                          <Icon
                            className="website_po_card_icon"
                            type="check-circle"
                            theme="twoTone"
                            twoToneColor="#52c41a"
                          />
                        )}
                        <Meta
                          title={`${item?.firstName} ${item?.lastName}`}
                          description={item?.school?.name}
                        />
                      </div>
                    </div>
                  </Card>
                );
              })}
              {filteredList &&
                filteredList?.length !== 0 &&
                page !== totalpage && (
                  <Col span={24}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        type="primary"
                        shape="round"
                        size="small"
                        onClick={loadmoreHandler}
                        style={{ height: "30px" }}
                        title={loadMore ? "Load more..." : "Load more"}
                      >
                        {loadMore ? "Load more..." : "Load more"}
                      </Button>
                    </div>
                  </Col>
                )}
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {filteredList?.length == 0 && "No students to display"}
              </div>
            </div>

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Form.Item style={{ marginTop: 10 }}>
                <Button
                  style={{ marginLeft: 10, height: "30px" }}
                  title="Cancel"
                  onClick={onModalCancel}
                >
                  Cancel
                </Button>

                <Button
                  title={isMultiple ? "Add Selections" : "Add Selection"}
                  style={{ marginLeft: 10, height: "30px" }}
                  type="primary"
                  disabled={selectedOptions?.length == 0}
                  onClick={onSubmit}
                >
                  {isMultiple
                    ? `Add Selections (${selectedOptions?.length ?? ""})`
                    : "Add Selection"}
                </Button>
              </Form.Item>
            </div>
          </Spins>
        </div>
      </Modal>
    </>
  );
}
