import { Component } from "react";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import FilterBoxWrapper from "./FilterBox.style";
import { Form, Layout, Icon, DatePicker, Input } from "antd";
import Button from "../uielements/button";
import Select, { SelectOption } from "../uielements/select";
import FilterStyle from "./Filter.Style";
import API from "../../helpers/API";
import StudentActions from "../../redux/users/actions";
import {
  getOrganizationsAuth,
  getQualityPermission,
  getRole,
} from "../../redux/auth/selectors";
import { getUsers } from "../../redux/users/selecters";
import Moment from "moment";
import createNotification from "../../components/notification";
import SchoolActions from "../../redux/schools/actions";
import OrganizationActions from "../../redux/organizations/actions";
import { getOrganizations } from "../../redux/admin/selectors";
import { getClassrooms } from "../../redux/MyClassroom/selecters";
import { getSchools } from "../../redux/schools/selecters";
import classrooms from "../../redux/MyClassroom/actions";

const { RangePicker } = DatePicker;
const { Header, Content } = Layout;
const Option = SelectOption;
const { fetchSchools } = SchoolActions;
const { fetchOrganizations } = OrganizationActions;

class Filter extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
      school: undefined,
      schools: undefined,
      classroom: undefined,
      organizations: undefined,
      classrooms: undefined
    };
  }
  componentDidMount() {
    // this.getRules();
    this.props?.fields?.forEach((field) => {
      if (
        (field === "school" || field === "schools") &&
        !this.props.schoolList
      ) {
        this.props.fetchSchools((val) => {});
      } else if (field === "classroom" && !this.props.classroomList) {
        this.props.fetchClassrooms(() => {});
      } else if (field === "organization" && !this.props.orgList) {
        this.props.fetchOrganizations(() => {});
      }
    });
    this.props.preFilter &&
      this.setState({
        school: this.props.preFilter?.school,
        schools: this.props.preFilter?.schools,
        classroom: this.props.preFilter?.classroom,
        classrooms: this.props.preFilter?.classrooms,
        organization: this.props.preFilter?.organization,
        organizations: this.props.preFilter?.organizations,
      });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      isOpen: nextProps.isSliderOpen,
    });
    this.props.preFilter &&
      this.setState({
        school: this.props.preFilter?.school,
        schools: this.props.preFilter?.schools,
        classroom: this.props.preFilter?.classroom,
        classrooms: this.props.preFilter?.classrooms,
        organization: this.props.preFilter?.organization,
        organizations: this.props.preFilter?.organizations,
      });
  }

  handleSchoolChange = (val) => {
    this.setState({ school: parseInt(val) === -1 ? undefined : val });
    this.handleSubmit({ school: parseInt(val) === -1 ? undefined : val });
  };

  handleSchoolsChange = (val) => {
    this.setState({ schools: parseInt(val) === -1 ? undefined : val });
    this.handleSubmit({ schools: parseInt(val) === -1 ? undefined : val });
  };

  handleClassroomChange = (val) => {
    this.setState({ classroom: parseInt(val) === -1 ? undefined : val });
    this.handleSubmit({ classroom: parseInt(val) === -1 ? undefined : val });
  };
  handleClassroomsChange = (val) => {
    this.setState({ classrooms: parseInt(val) === -1 ? undefined : val });
    this.handleSubmit({ classrooms: parseInt(val) === -1 ? undefined : val });
  };
  handleOrganizationChange = (val) => {
    this.setState({ organization: parseInt(val) === -1 ? undefined : val });
    this.handleSubmit({ organization: parseInt(val) === -1 ? undefined : val });
  };
  handleOrganizationsChange = (val) => {
    this.setState({ organizations: parseInt(val) === -1 ? undefined : val });
    this.handleSubmit({ organizations: parseInt(val) === -1 ? undefined : val });
  };

  resetFilter = (e) => {
    e.preventDefault();
    this.setState(
      {
        appliedStatus: undefined,
      },
      () => {
        this.props.submit({});
      }
    );
  };

  handleSubmit = (state) => {
    // e.preventDefault();
    this.props.submit(state);
  };

  render() {
    const formLayout = "horizontal";
    const { isOpen } = this.state;
    const classArr = this.props.classroomList ?? this.props.classroom;
    const schoolArr = this.props.schoolList ?? this.props.schools;
    const orgArr = this.props.orgList ?? this.props.organizations;

    return (
      // <FilterStyle
      //   className={isOpen ? "isoThemeSwitcher active" : "isoThemeSwitcher"}
      //   style={{ paddingBottom: 20 }}
      // >
      //   <FilterBoxWrapper>
      //     <Layout className="isoFilterBoxWrapper">
      //       <Header className="isoHeader filter-header">
      //         <span onClick={() => this.props.close()}>
      //           <Icon
      //             key={"close"}
      //             type={"close"}
      //             style={{ cursor: "pointer" }}
      //             title="Close"
      //           />
      //         </span>
      //         <span style={{ marginLeft: 170 }}></span>
      //       </Header>
            <Content
              className="isoFilterContent"
              // style={{ overflowY: "scroll" }}
            >
              <Form
                layout={formLayout}
                className="form-kra"
                // onSubmit={this.handleSubmit}
              >
                <div style={{ borderRadius: "10px", padding: "10px", margin: "0 10px", display: "flex"}} className="student-select-wrapper">
                {this.props.fields.includes("organization") && (
                    <Form.Item label="Organization">
                      <Select
                        showSearch
                        showArrow={false}
                        onChange={this.handleOrganizationChange}
                        style={{ width: "280px" }}
                        placeholder={"Select Org"}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        value={this.state?.organization}
                        disabled={this.props.orgDisabled}
                        // loading={schoolLoader}
                      >
                        <Option key={""} value={-1}>
                          Any Organization
                        </Option>
                        {orgArr &&
                          orgArr.map((org, key) => (
                            <Option key={key} value={org.id}>
                              {org.name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  )}
                  {this.props.fields.includes("organizations") && (
                    <Form.Item label="Organizations">
                      <Select
                        showSearch
                        showArrow={false}
                        onChange={this.handleOrganizationsChange}
                        style={{ width: "280px" }}
                        placeholder={"Select Org"}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        value={this.state?.organizations}
                        mode="multiple"
                        maxTagCount={1}
                        // loading={schoolLoader}
                        disabled={this.props.orgDisabled}
                      >
                        <Option key={""} value={-1}>
                          Any Organization
                        </Option>
                        {orgArr &&
                          orgArr.map((org, key) => (
                            <Option key={key} value={org.id}>
                              {org.name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  )}
                  {this.props.fields.includes("school") && (
                    <Form.Item label="School">
                      <Select
                        showSearch
                        showArrow={false}
                        onChange={this.handleSchoolChange}
                        style={{ width: "280px" }}
                        placeholder={"Select School"}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        value={this.state.school}
                        // disabled={this.props.preFilter?.school}
                        // loading={schoolLoader}
                      >
                        {/* <Option key={""} value={-1}>
                          Any School
                        </Option> */}
                        {schoolArr &&
                          schoolArr.map((school, key) => (
                            <Option key={key} value={school.id}>
                              {school.name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  )}
                  {this.props.fields.includes("schools") && (
                    <Form.Item label="Schools">
                      <Select
                        showSearch
                        showArrow={false}
                        onChange={this.handleSchoolsChange}
                        style={{ width: "280px" }}
                        placeholder={"Select Schools"}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        value={this.state.schools}
                        mode="multiple"
                        maxTagCount={1}
                        // disabled={this.props.preFilter?.school}
                        // loading={schoolLoader}
                      >
                        <Option key={""} value={-1}>
                          Any School
                        </Option>
                        {schoolArr &&
                          schoolArr.map((school, key) => (
                            <Option key={key} value={school.id}>
                              {school.name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  )}
                  {this.props.fields.includes("classroom") && (
                    <Form.Item label="Classroom">
                      <Select
                        showSearch
                        showArrow={false}
                        onChange={this.handleClassroomChange}
                        style={{ width: "280px" }}
                        id="classroom"
                        allowClear
                        placeholder={"Select Classroom"}
                        filterOption={false}
                        value={this.state?.classroom}
                      >
                        <Option key={""} value={-1}>
                          Any class
                        </Option>
                        {classArr &&
                          classArr.map((classroom, key) => (
                            <Option key={key} value={classroom.id}>
                              {classroom.name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  )}
                  {this.props.fields.includes("classrooms") && (
                    <Form.Item label="Classrooms">
                      <Select
                        showSearch
                        showArrow={false}
                        onChange={this.handleClassroomsChange}
                        style={{ width: "280px" }}
                        id="classroom"
                        mode="multiple"
                        maxTagCount={1}
                        placeholder={"Select Classrooms"}
                        filterOption={false}
                        value={this.state?.classrooms}
                      >
                        <Option key={""} value={-1}>
                          Any class
                        </Option>
                        {classArr &&
                          classArr.map((classroom, key) => (
                            <Option key={key} value={classroom.id}>
                              {classroom.name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  )}
                  
                </div>
                {/* <Form.Item style={{ marginTop: 10 }}>
                  <Button onClick={this.handleSubmit} title="Filter">
                    Filter
                  </Button>
                  <Button
                    style={{ marginLeft: 10 }}
                    type="primary"
                    htmlType="button"
                    onClick={(e) => this.resetFilter(e)}
                    title="Clear"
                  >
                    Clear
                  </Button>
                </Form.Item> */}
              </Form>
            </Content>
      //     </Layout>
      //   </FilterBoxWrapper>
      // </FilterStyle>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  organizations: getOrganizations(),
  classroom: getClassrooms(),
  schools: getSchools(),
});

function mapDispatchToProps(dispatch, ownProps) {
  return {
    fetchSchools: (cb) => dispatch(fetchSchools(cb)),
    fetchOrganizations: (cb) => dispatch(fetchOrganizations(cb)),
    fetchClassrooms: (spinHandler, payload) =>
      dispatch(classrooms.fetchClassroomsv(spinHandler, payload)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)((Filter = Form.create()(Filter)));
