/**
 * User selectors
 */

import { createSelector } from 'reselect';

const selectSchools = state => state.Schools;

const getAllSchools = () => createSelector(
    selectSchools,
    (schoolState) => schoolState.get('schools')
);

const getSearchKey = () => createSelector(
    selectSchools,
    (schoolState) => schoolState.get('key')
);

const getSchools = () => createSelector(
    getAllSchools(), getSearchKey(),
    (allSchools, key) =>((allSchools) && (
        allSchools.filter((school) => {
            key = key ? key.toLowerCase(): '';
            let val = '';
            if(key){
              val =  school && (
              ( school.name.toLowerCase().indexOf(key) > -1 ||
                school.address && school.address.toLowerCase().indexOf(key) > -1 ||
                school.email && school.email.toLowerCase().indexOf(key) > -1 ||
                school?.phone && school?.phone.toString().indexOf(key) > -1)
              )
            }else{
              val = true
            }
            return val;
        })
    ))    
);

const getSchoolDetails = () => createSelector(
    selectSchools,
    (schoolState) => schoolState.get('school')
);

export {
    getSchools,
    getSchoolDetails,
};
