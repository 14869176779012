import styled from "styled-components";
import { palette } from "styled-theme";
import { transition, borderRadius, boxShadow } from "../../settings/style-util";
import WithDirection from "../../settings/withDirection";

const FilterStyle = styled.div`
  background-color: #ffffff;
  width: 400px;
  height: calc(100% - 70px);
  padding: 0 0 50px;
  flex-shrink: 0;
  position: fixed;
  top: 70px;
  right: ${props => (props["data-rtl"] === "rtl" ? "inherit" : "-400px")};
  left: ${props => (props["data-rtl"] === "rtl" ? "-400px" : "inherit")};
  z-index: 1011;
  ${transition()};
  ${boxShadow("-1px 0 5px rgba(0,0,0,0.25)")};

  @media only screen and (max-width: 767px) {
    width: 400px;
    right: ${props => (props["data-rtl"] === "rtl" ? "inherit" : "-400px")};
    left: ${props => (props["data-rtl"] === "rtl" ? "-400px" : "inherit")};
  }

  &.active {
    right: ${props => (props["data-rtl"] === "rtl" ? "inherit" : "0")};
    left: ${props => (props["data-rtl"] === "rtl" ? "0" : "inherit")};
  }

  @-webkit-keyframes selectedAnimation {
    0% {
      -webkit-transform: scale(0.8);
      transform: scale(0.8);
      opacity: 0.5;
    }
    100% {
      -webkit-transform: scale(2.4);
      transform: scale(2.4);
      opacity: 0;
    }
  }
  @keyframes selectedAnimation {
    0% {
      -webkit-transform: scale(0.8);
      transform: scale(0.8);
      opacity: 0.5;
    }
    100% {
      -webkit-transform: scale(2.4);
      transform: scale(2.4);
      opacity: 0;
    }
  }
`;

export default WithDirection(FilterStyle);
