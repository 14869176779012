/**
 * classroom selectors
 */

import { createSelector } from "reselect";

const selectClassroom = (state) => state.Classrooms;

const getSingleClassroom = () =>
  createSelector(selectClassroom, (classroomState) =>
    classroomState.get("singleClassroom")
  );

const getSingleClassroomChatSelecter = () =>
  createSelector(selectClassroom, (classroomState) =>
    classroomState.get("singleClassroomChat")
  );

const getAllClassrooms = () =>
  createSelector(selectClassroom, (classroomState) =>
    classroomState.get("classrooms")
  );
const getMyClassrooms = () =>
  createSelector(selectClassroom, (state) => state.get("myClassrooms"));

const getSearchKey = () =>
  createSelector(selectClassroom, (classroomState) =>
    classroomState.get("key")
  );
  const getSingleUnit = () =>
  createSelector(selectClassroom, (courseState) => courseState.get("units"));

const getClassrooms = () =>
  createSelector(
    getAllClassrooms(),
    getSearchKey(),
    (allClassroomAdmin, key) =>
      allClassroomAdmin &&
      allClassroomAdmin.filter((classroomAdmin) => {
        key = key ? key.toLowerCase() : "";
        let val = "";
        if (key) {
          val =
            classroomAdmin &&
            classroomAdmin.name.toLowerCase().indexOf(key) > -1;
        } else {
          val = true;
        }
        return val;
      })
  );

export {
  getSingleClassroom,
  getSingleClassroomChatSelecter,
  getClassrooms,
  getMyClassrooms,
  getSingleUnit
};
