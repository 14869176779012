export const pageSize = 10;
export const reloadCurrentPage = 1;
export const reloadTable = 2;

export const getTableValues = async (param = {}, setState, api, key) => {
  Object.keys(param).forEach(
    (key) =>
      ((param[key] === undefined) || (param[key] === "")) && delete param[key]
  );
  if (param.page == 1) {
    if (param.sortOrder && param.sortBy) {
    }
    setState({
      loader: true,
      current: 1,
    });
  } else {
    setState({ loader: true });
  }
  try {
    const res = await api(param);
    setState({
      loader: false,
      fetchParams: param,
      totalCount: res?.data?.pagination?.totalCount || res?.data?.count || 0,
      list: res?.data?.[key] || res?.data?.requests || res?.data?.data || [],
      totalPages: res?.data?.pagination?.totalPages || res?.data?.count || 0,
    });
  } catch (e) {
    setState({ loader: false });
  }
};

export const formatObject = (object = {}) => {
  let copyObj = {};
  for (const property in object)
    if (object[property] !== undefined) copyObj[property] = object[property];
  return copyObj;
};

export const formatExportParam = (param) => {
  param = { ...param, export: true };
  delete param.offset;
  delete param.limit;
  return param;
};

export function getSortParam(sorter) {
  try {
    if (sorter?.order && sorter?.columnKey) {
      const param = {
        sortOrder: sorter?.order === "descend" ? "DESC" : "ASC",
        sortBy: sorter?.columnKey || "",
      };
      return param;
    }
    return {
      sortOrder: "",
      sortBy: "",
    };
  } catch (err) {
    return {
      sortOrder: "",
      sortBy: "",
    };
  }
}
