/**
 * User selectors
 */

import { createSelector } from "reselect";

const selectUsers = (state) => state.Admin;

const getAllUsers = () =>
  createSelector(selectUsers, (userState) => userState.get("users"));

const getUserDetails = () =>
  createSelector(selectUsers, (userState) => userState.get("user"));

const getOrganizations = () =>
  createSelector(selectUsers, (userState) => userState.get("organizations"));

const getOrganizationsSchool = () =>
  createSelector(selectUsers, (userState) => userState.get("schools"));

const getOrganizationsCourse = () =>
  createSelector(selectUsers, (userState) => userState.get("courses"));

const getSearchKey = () =>
  createSelector(selectUsers, (userState) => userState.get("key"));

const getUsers = () =>
  createSelector(
    getAllUsers(),
    getSearchKey(),
    (allUsers, key) =>
      allUsers &&
      allUsers.filter((user) => {
        key = key ? key.toLowerCase() : "";
        let val = "";
        if (key) {
          val =
            user &&
            (user.firstName.toLowerCase().indexOf(key) > -1 ||
              (user.lastName && user.lastName.toLowerCase().indexOf(key) > -1));
        } else {
          val = true;
        }
        return val;
      })
  );

const getConfigs = () =>
  createSelector(selectUsers, (userState) => {
    return {
      schools: userState.get("schools"),
      organizations: userState.get("organizations"),
      roles: userState.get("roles"),
      subjects: userState.get("subjects"),
      grades: userState.get("grades"),
      teachers: userState.get("teachers"),
    };
  });

export {
  getUsers,
  getUserDetails,
  getOrganizations,
  getConfigs,
  getOrganizationsSchool,
  getOrganizationsCourse,
};
